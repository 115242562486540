/* form */
.form {
    width:40%;
    flex-direction:column;
}

.table-contact {
border-collapse:collapse;
}

    .table-contact h3 {
        font-size:20px;
        font-weight:600;
    }

    .table-contact p {
        font-size: 20px;
        font-weight: 200;
    }

    .table-contact .main_button {
        font-weight: 200;
    }

    .col1-contact {
        text-align: center;
        padding: 20px 20px 20px 0px;
    }

.col2-contact {
    text-align:left;
    border-left: 2px solid #14f3db;
    padding:20px;
}

.formResult {
    width: 40%;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    text-align: center;
    gap: 10px;
}

.formResult h2 {
    padding:0 !important;
}

    .formResult h3 {
        font-size: 24px;
    }

    .formResult p{
        font-size: 20px;
    }

.form input {
    background-color: transparent;
    border: none;
    border-bottom: solid 2px #14f3db;
    padding: 20px 5px;
    margin-bottom: 25px;
    font-size: 20px;
    color: white;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: white;
}

::-ms-input-placeholder { /* Microsoft Edge */
    color: white;
}

.form_btn {
    text-align: left;
    margin-top: 60px;
}
    
.form_content {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}


.form_btn input {
    border: solid 2px #14f3db;
    border-radius: 25px;
    padding:10px;
    background: linear-gradient(to left, transparent 50%, #14f3db 50%) right;
    background-size: 200%;
    transition: .5s ease-out;
    color: white;
    width:80%;
    display:flex;
    justify-content:center;
    justify-self:center;
    margin:auto;
}

    .form_btn input:hover {
        background-position: left;
        cursor: pointer;
        color:black;
    }

@keyframes fill {
    from {
        background-color: none;
    }

    to {
        background-color: #14f3db;
    }
}

.contact_items {
    display: flex;
    gap: 100px;
    width:100%;
    justify-content:center;
    flex-wrap:wrap;
}


.main_contact_container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 70px;
}

.contact_details {
    display: flex;
    flex-direction: column;
    width:40%;
    gap: 20px;
}



.IconContact {
    font-size:40px;
    display:flex;
    color:white;
}


.contact_description p{
    display: inline;
}

.contact_container {
    display: flex;
    flex-direction: column;
}

.subtitle_contact {
    font-size: 20px;
    margin-bottom: 20px;
}

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
}

.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 4;
    stroke-miterlimit: 10;
    stroke: #14f3db;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards
}

.checkmark {
    width: 156px;
    height: 156px;
    border-radius: 50%;
    display: block;
    stroke-width: 4;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 5% auto;
    box-shadow: inset 0px 0px 0px #14f3db;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both
}

.checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0
    }
}

@keyframes scale {

    0%, 100% {
        transform: none
    }

    50% {
        transform: scale3d(1.1, 1.1, 1)
    }
}

@keyframes fill {
    100% {
        box-shadow: inset 0px 0px 0px 90px #14f3db
    }
}