
.legal_container {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin: 80px auto 0px;
}

.legal {
    display: inline-flex;
    width: 70%;
margin:auto;    flex-direction: column;
    gap: 2px;
    color:white;
}


    .legal li {
        padding-left: 20px;
        display: flex;
        gap: 10px;
    }

    .legal h3 {
        color: #14f3db !important;
    }

    .legal li::before {
        content: '';
        width: 7px;
        display: flex;
        height: 7px;
        align-self: center;
        -moz-border-radius: 7.5px;
        -webkit-border-radius: 7.5px;
        border-radius: 7.5px;
        background-color: #14f3db;
    }

    .legal a {
        color: white;
    }

        .legal a:hover {
            color: #14f3db;
        }
