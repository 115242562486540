@import url('https://fonts.googleapis.com/css2?family=Helvetica&display=swap');

:root {
    --primary: #14f3db;
    --primary_hover: #0fc3b0;
    --primary_text: black;
    --second: #1C1C1C;
    --background: #323031;
    --bar_menu_inactive: transparent;
    --shadow: 7px 5px 10px 3px rgba(0,0,0,0.2);
    --button_hover: #f0f0f0;
}

.nav_bar {
    background-color: var(--bar_menu_inactive) !important;
    transition: 0.5s !important;
    backdrop-filter: blur(3px);
}

    .nav_bar:hover {
        background-color: var(--second) !important;
    }


.accordion_summary {
    margin-top: 8px !important;
    background-color: var(--primary) !important;
    color: var(--second) !important;
    font-weight: bold !important;
    text-transform: uppercase !important;
    border-radius: 5px !important;
}

.education_container .MuiTimeline-root {
    padding: 0px !important;
}

.education_container .sub_title {
    font-size: 20px !important;
}

.education_container .sub_title_svg {
    font-size: 30px !important;
}

    .accordion_summary:hover, .accordion_summary:active {
        background-color: var(--primary_hover) !important;
        box-shadow: none !important;
    }


p {
    margin-bottom: unset;
}

a:hover {
    color: #14f3db;
    text-decoration: none;
}


body {
    font-family: 'Helvetica', sans-serif;
    line-height: 1.4;
    color: #444;
    background: var(--background);
    height: 100vh;
}


html {
    overflow-x: hidden;
}

#root {
    background-color: #323031;
}

.pragmantic_partners {
    background-color:black;
    padding:2px 5px;
    font-size:10px;
    color:white;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    text-decoration: none;
}

input:focus {
    outline: none;
    color:white;
}

#menu_open .MuiDrawer-paper {
    background-color: var(--background) !important;
}

.menu_open svg {
    background-color: var(--background) !important;
    color: white;
}

.menu_open .MuiList-root .MuiListItem-root {
    padding: 15px 20px;
}

    .menu_open .MuiList-root .MuiListItem-root:hover * {
        color: var(--primary) !important;
    }

    .menu_open .MuiList-root .MuiListItem-root:hover svg {
        animation: bounce 0.3s ease-in-out;
    }


.menu_open_links a:hover svg {
    color: var(--primary);
    animation: bounce 0.3s ease-in-out;
}


@keyframes bounce {
    0%, 100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }
}


.animated_text {
    background-color: var(--second);
    border-radius: 10px;
    padding: 10px 20px;
}

.home_button  {
    position:relative;
}

.home_button {
    padding: 10px 20px 5px 20px;
    border-radius: 10px;
    backdrop-filter: blur(5px);
    background-color: var(--second);
    box-shadow: 10px 7px 13px 3px rgba(0,0,0,0.2);
    position: relative;
    transition: 1s;
}

    .home_button a:hover {
        transition: 0.5s;
        color: var(--primary) !important;
    } 
    
    .home_button a:hover {
        color:inherit;
    }

        .home_button:hover {
            transform: scale(1.1);
        }

        .home_button .home_button_svg {
            position: absolute;
            height:57px;
            background-color: var(--second);
            padding: 10px;
            border-radius: 10px;
            left: -70px;
            top: 0;
            color: var(--primary);
        }

        .home_button:hover .home_button_svg {
            transform: rotate(-10deg);
        }

.button {
    padding: 10px 40px;
    border-radius: 15px;
    background: #14f3db;
    align-items: center;
    text-align: center;
    font-size: 120%;
    margin-top: 40px;
    display: inline-block;
    width: fit-content;
}

.button:hover {
    background-color: #00ab98;
    color: white;
    transform: translateX(10px);
    transition: 0.4s ease-out;
}

.off {
    display:none;
}

.main {
    display: flex;
    flex-direction: column;
    padding: 130px 0;
    width: 1400px;
    margin: auto;
    gap: 60px;
}

.main h1 {
    text-align:center;
    text-transform:uppercase;
    padding-top:75px;
    padding-bottom:10px;
    font-size:70px;
    color:white;
    background-color:var(--second);
    position:absolute;
    top:0;
    width:100%;
    left:0;
    border-bottom: 3px solid var(--primary);
}

.detailsOn h1 {
    padding-left: 100px;
    padding-right: 100px;
}


    .main h2 {
        padding: 10px 0px;
        margin: 30px 0px;
        font-size: 60px;
        color: white;
        z-index: 100;
    }

.main_button {
    all: unset;
    display: inline-block;
    cursor: pointer;
    color: white;
    padding: 3px 10px;
    border-radius: 5px;
    transition: 0.5s;
    background-color: var(--primary);
}

.card_box {
    background-color: var(--second) !important;
    color: white !important;
    padding: 5px !important;
    border-radius:10px !important;
    box-shadow: var(--shadow) !important;
}

.card_p_title {
    color:var(--primary);
}

.project_card_content_list {
    font-size:20px;
}


.main_button:hover {
    background-color: var(--primary_hover);
    color: white;
}

.main p {
    color: white;
}

    .main h3 {
        color: white;
    }

    a, button {
       cursor:pointer;
    }

.hero_textcontainer h2 {
    text-align:right;

}

.hero_textcontainer {
    flex-wrap:wrap;
    justify-content:center;

}

.hero_textcontainer ul h2 {
    text-align:left;

}


@media only screen and (max-width: 1400px ) {
    .main {
        width: 1200px;
    }

    .education_main {
        gap: 60px;
    }

    .heroBackground {
        text-align: center;
    }

}
  

@media only screen and (max-width: 1200px )  {
    .main {
        width:960px;
    }

    .educationAside {
        max-width: 400px;
    }

    .aboutContainer, .aboutContainerText {
        flex-wrap: wrap;
        justify-content: center;
        align-content: center;
        text-align: center;
    }

    .aboutButton, .aboutContainerDescription {
        justify-content: center;
        align-content: center;
        text-align: center;
    }

    .aboutImg {
        height: 450px;
        width:350px;
        margin: 0 80px;
        background-position: top center;
        margin-top: 100px;
        border-radius: 15px;
    }

}

@media only screen and (max-width: 992px ) {
    .main {
        width: 720px;
    }


    .education_container .sub_title {
        font-size: 10px !important;
    }

    #sub_title_date {
        max-width:45px !important;
    }

    .education_container .MuiTimelineItem-root > div {
        padding: 0px 5px !important;
    }

    .accordion_details p, .accordion_details button {
        font-size: 10px !important;
    }

    #pragmantic_container {
        display:none;
    }
    .education_container .sub_title_svg {
        font-size: 15px !important;
    }

    .contact_details {
        width: 100%;
    }

    .form {
        width: 100%;
    }

    .education_main {
        padding:0;
    }

    .education_card {
        width: 250px;
    }

    .education_container {

        align-items:center;
    }

    .heroSubtitle {
        flex-wrap:wrap;
        justify-items:center;
        text-align:center;
    }

    .education_container .MuiAccordion-heading  button {
        padding-right: 0 !important;
    }


    .HomeButton {
        width: 100%;
        justify-content: center;
    }

    

    .heroSubtitle {
        justify-content: center;
        height: unset;
        flex-direction: column;
        align-items: center;
    }


}

@media only screen and (max-width: 850px ) {

    .heroSubtitle h2, .heroSubtitle li {
        font-size: 1rem;
    }

    .heroSubtitle svg, .home_li_before {
        width: 30px;
        height: 30px;
        transform: unset;
    }


    .HomeButton {
        margin-left: 0;
    }


 
    .button {
        padding: 10px 20px;
    }


    .imageExp {
        min-width: unset;
    }

    .main h1 {
     font-size: 45px;
    }

    .main h2 {
        font-size: 40px;
        text-align:center;
    }

    .main {
        width: 540px;
    }

    .educationAside {
        display: none;
    }

    .responsive {
        display: block;
    }

    .education_card {
        width: 100%;
    }

    .education_main {
        padding: 0 0px 0 80px;
    }

    .aboutDescription h4 {
       font-size: 20px;
    }

    .aboutDescription h2 {
        font-size: 30px;
    }

    .aboutDescription p {
        font-size: 15px;
    }

    .detailsOn {
        padding: 20px;
    }

        .detailsOn  ul{
            text-align: left;
        }
        
        .detailsOn  ul li{
            padding: 8px 0px;
        }

        .detailsOn h1 {
            font-size: 30px !important;
            padding:0;
        }

            .detailsOn h2 {
                font-size: 25px !important;
                padding: 0px 0px 40px 0px;
                text-transform: uppercase;
                color: #14f3db;
            }

    .descrText {
        align-items:center;
    }

    .container-reverse {
        flex-direction: column-reverse;
    }


    .container {
        flex-wrap:wrap;
        text-align:center;
        padding:0;
    }

    .descriptionRightTwo a, .descriptionRight a, .imageExp {
        margin-left: auto;
        margin-right: auto;
    }

    .imageExp {
        margin-left: auto;
        margin-right: auto;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .descriptionLeftTwo {
        min-width:300px;
    }

    .heroContent h1 {
        font-size: 540%;
    }

    .heroSubtitle, .dropping-texts {
        font-size: 25px;
    }

}

@media only screen and (max-width: 576px ) {
    .main {
        width: 85%;
    }

    footer ul {
        gap: 20px;
        font-size:12px;
    }

    .aboutImg {
        height: 250px;
    }

    .aboutContainer div {
        width: 100%;
    }

    .aboutButton {
        flex-wrap:wrap;
        gap:50px;
    }

    .aboutSocial {
        justify-content:center;
    }

    .aboutContainerText {
        gap : 30px;
    }

    .aboutDescription {
        padding-bottom: 20px;
    }

    .aboutImg {
        margin-top: 0px;
    }

    .heroContent h1 {
        font-size: 330%;
    }

    .heroSubtitle, .dropping-texts {
        font-size: 17px;
    }

    .pragmantic_button {
        display:none;
    }

    }


canvas {
    display: block;
    vertical-align: bottom;
}
/* ---- tsparticles container ---- */
#tsparticles {
    position: absolute;
    width: 100%;
    height: calc( 100% - 64px );
    background-color: #323031;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
}

.github {
    bottom: 10px;
    right: 10px;
    position: fixed;
    border-radius: 10px;
    background: #fff;
    padding: 0 12px 6px 12px;
    border: 1px solid #000;
}

    .github a:hover,
    .github a:link,
    .github a:visited,
    .github a:active {
        color: #000;
        text-decoration: none;
    }

    .github img {
        height: 30px;
    }

    .github #gh-project {
        font-size: 20px;
        padding-left: 5px;
        font-weight: bold;
        vertical-align: bottom;
    }